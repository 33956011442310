import React from 'react'
import bannerLat from "../../assets/banner.png"

export default function SlideOne() {
    return (
        <div className="w-full h-max flex items-center justify-center">
            <div className="flex justify-center items-center h-[70vh] w-[95%] bannerswitch:h-[50vh]">
                <div className="  w-full h-full flex justify-center items-center">
                    <img src={bannerLat} alt="BannerZanshin" className="w-full" />
                </div>
            </div>
        </div>
    )
}

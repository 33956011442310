import React from 'react'
import bannerProduse from "../../assets/banner3.png"

export default function SlideThree() {
    return (
        <div className="w-full h-max ">
            <div className="flex justify-center items-center h-[70vh] w-[95%] bannerswitch:h-[50vh]">
            <div className=" w-full h-full flex justify-center items-center">
                    <img src={bannerProduse} alt="BannerZanshin" className="h-full bannerswitch:w-full bannerswitch:h-max" />
                </div>
            </div>
        </div>
    )
}

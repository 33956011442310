import instagram from "./assets/iglogo.png";
import Intro from "./sections/Intro.client";
import IntroMobile from "./sections/IntroMobile.client";
import NewIntro from "./sections/NewIntro.client";

import MainHero from "./sections/MainHero.client";
import NewIntroMobile from "./sections/NewIntroMobile.client";

function App() {
  return (
    <>

       
      <div className="forthswitch:hidden w-full">
      <NewIntro />
      </div>
      <div className="hidden forthswitch:flex w-full">
        <NewIntroMobile />
      </div>
    </>
  );
}

export default App;

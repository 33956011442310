import React from 'react'
import bannerFata from "../../assets/banner2.png"

export default function SlideTwo() {
  return (
    <div className="w-full h-max ">
    <div className="flex justify-center items-center h-[70vh] w-[95%] bannerswitch:h-[50vh]">
    <div className=" w-full h-full flex justify-center items-center">
                    <img src={bannerFata} alt="BannerZanshin" className="h-full bannerswitch:w-full bannerswitch:h-max" />
                </div>
    </div>
</div>
  )
}
